import React, { useEffect, useState } from 'react';
import './Widget.scss';
import { GraphLine } from '../GraphLine/GraphLine';
import { GraphNumber } from '../GraphNumber/GraphNumber';
import { deleteWidget, editWidget, getWidgetById } from '../../data/api';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { GraphButton } from '../GraphButton/GraphButton';
import { GraphSwitch } from '../GraphSwitch/GraphSwitch';
import { GraphList } from '../GraphList/GraphList';
import { GraphSlider } from '../GraphSlider/GraphSlider';

export const Widget = ({ type, data, title, id, unit, maxLimitActive, minLimitActive, maxValue, minValue }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedType, setEditedType] = useState(type);
  const [editedTitle, setEditedTitle] = useState(title);
  const [maxAlert, setMaxAlert] = useState(maxValue);
  const [minAlert, setMinAlert] = useState(minValue);
  const [maxAlertEnabled, setMaxAlertEnabled] = useState(maxLimitActive);
  const [minAlertEnabled, setMinAlertEnabled] = useState(minLimitActive);
  const [finalData, setFinalData] = useState(data);
  

  useEffect(() => {
    const getData = async () => {
      const resp = await getWidgetById(id);
      if (resp.status === 'success') {
        const data = (resp.widget.type === 'line' || resp.widget.type === 'number') ? resp.widget.data.data : null;
        if (resp.widget.type === 'line') {
          setFinalData({
            labels: data.map(item => moment(item.timestamp).format('HH:mm:ss DD/MM/YYYY')),
            datasets: [
              {
                label: resp.widget.data.variable,
                data: data.map(item => item.value),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
              },
            ],
          });
        }
        if (resp.widget.type === 'number') {
          const aux = data[data.length - 1];
          setFinalData({
            value: aux.value,
            timestamp: aux.timestamp,
            unit: aux.unit,
          });
        }
        if(data == null){
          setFinalData(resp.widget)
        }
      }
    };

    /* const interval = setInterval(getData, 5000);
    return () => clearInterval(interval); */
  }, [id]);

  const toggleEdit = () => setIsEditing(!isEditing);

  const handleSave = async () => {

    if(maxAlert && minAlert && maxAlert <= minAlert) {
      toast.error('El alerta máximo debe ser más grande que el mínimo')
      return
    }

    const resp = await editWidget(id, {
      type: editedType,
      title: editedTitle,
      maxValue: maxAlertEnabled ? maxAlert : null,
      minValue: minAlertEnabled ? minAlert : null,
      maxLimitActive: maxAlertEnabled,
      minLimitActive: minAlertEnabled,
    });
    if (resp.status === 'success') {
      toast.success('Widget editado exitosamente!', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
        transition: Bounce,
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    toggleEdit();
  };

  const onDelete = async () => {
    const resp = await deleteWidget(id);
    if (resp.status === 'success') {
      toast.success('Widget eliminado exitosamente!', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        theme: 'colored',
        transition: Bounce,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  // Asegúrate de que los cambios en los checkboxes llamen al setState correspondiente y registra cada cambio
  const handleMaxAlertToggle = () => {
    setMaxAlertEnabled((prev) => {
      console.log("Nuevo estado de maxAlertEnabled:", !prev);
      return !prev;
    });
  };

  const handleMinAlertToggle = () => {
    setMinAlertEnabled((prev) => {
      console.log("Nuevo estado de minAlertEnabled:", !prev);
      return !prev;
    });
  };

  return (
    <div className="widget" id={id}>
      <div className="widget-header">
        <h3>{title}</h3>
        <button className="edit-button" onClick={toggleEdit}>
          <span className="edit-icon">Editar</span>
        </button>
      </div>
      <div className="widget-content">
        {isEditing ? (
          <div className="widget-edit-form">
            <label htmlFor="title">Título</label>
            <input
              type="text"
              id="title"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />

            {(type == 'line' || type == 'number') && <label htmlFor="type">Tipo de gráfico</label>}
            {(type == 'line' || type == 'number') &&
            (<select
                id="type"
                value={editedType}
                onChange={(e) => setEditedType(e.target.value)}
              >
                <option value="line">Línea</option>
                <option value="number">Número</option>
              </select>)
            }

            {(type == 'line' || type == 'number') && 
            <div className="alerts-section">
              <div>
                <input
                  type="checkbox"
                  id="enable-max-alert"
                  checked={maxAlertEnabled}
                  onChange={handleMaxAlertToggle}
                />
                <label htmlFor="enable-max-alert">Activar alerta máxima</label>
              </div>

              {maxAlertEnabled && (
                <div>
                  <label htmlFor="max-alert">Alerta Máxima</label>
                  <input
                    type="number"
                    id="max-alert"
                    value={maxAlert}
                    onChange={(e) => setMaxAlert(e.target.value)}
                  />
                </div>
              )}
              <div>
                <input
                  type="checkbox"
                  id="enable-min-alert"
                  checked={minAlertEnabled}
                  onChange={handleMinAlertToggle}
                />
                <label htmlFor="enable-min-alert">Activar alerta mínima</label>
              </div>

              {minAlertEnabled && (
                <div>
                  <label htmlFor="min-alert">Alerta Mínima</label>
                  <input
                    type="number"
                    id="min-alert"
                    value={minAlert}
                    onChange={(e) => setMinAlert(e.target.value)}
                  />
                </div>
              )}
            </div>}

            { !(type == 'line' || type == 'number') && 
            <div className="topic-container">
              <p>Tópico:</p>
              <p className="topic">{data.topic}</p>
            </div>
            }

            <div className="edit-buttons">
              <button className="btn-save" onClick={handleSave}>Guardar</button>
              <button className="btn-cancel" onClick={toggleEdit}>Cancelar</button>
              <button className="btn-delete" onClick={() => onDelete(id)}>Eliminar Widget</button>
            </div>
          </div>
        ) : (
          <>
            {type === 'line' && <GraphLine data={finalData} unit={unit} />}
            {type === 'number' && <GraphNumber data={finalData} />}
            {type === 'button' && <GraphButton  data={finalData} />}
            {type === 'switch' && <GraphSwitch  data={finalData} />}
            {type === 'list' && <GraphList  data={finalData} />}
            {type === 'slider' && <GraphSlider  data={finalData} />}
          </>
        )}
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        theme="colored"
        transition={Bounce}
      />
    </div>
  );
};
