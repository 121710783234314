import mqtt from 'mqtt';

let client;

export const getClient = () => {
  if (!client) {
    const brokerUrl = 'wss://broker.emqx.io:8084/mqtt';
    const options = {
      clean: true,
      connectTimeout: 5000,
      clientId: `${localStorage.getItem('userId')}-${Math.random().toString(16).substr(2, 8)}`
    };
    client = mqtt.connect(brokerUrl, options);
    client.on('connect', () => console.log('Conectado al broker MQTT (cliente global)'));
    client.on('error', (error) => console.error('Error de conexión MQTT (cliente global):', error));
  }
  return client;
};
