import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavBar } from '../NavBar/NavBar';
import { HomeRouter } from './HomeRouter';
import { getStatus } from '../../data/api';

export const HomeContainer = ({ path }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    // Si no hay token, redirigir a la página de inicio ("/")
    if (!token) {
      navigate('/');
    }

    const getState = async () => {

      const resp = await getStatus()
      if(resp.status === 'Unauthorized') {
        console.log(resp);
        //Se venció el token
        navigate('/login');
      }
    }

    getState()
    setInterval(() => {
      if (!token) {
        navigate('/');
      }
      getState()
    }, 1000 * 60 * 5); // 5 minutos
    //getState()

  }, [navigate]); // Ejecutar el efecto solo cuando el componente se cargue

  return (
    <>
      <NavBar className="navbar" />
      <HomeRouter path={path} />
    </>
  );
};
