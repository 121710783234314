import React, { useEffect, useState } from 'react';
import './PopUpAddWidget.scss';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { createWidget, fetchUserData, fetchUserDevices, fetchUserWidgets } from '../../data/api';

export const PopUpAddWidget = ({ onClose }) => {
    const [title, setTitle] = useState('');
    const [buttonTitle, setButtonTitle] = useState('');
    const [buttonCommand, setButtonCommand] = useState('');
    const [sliderMin, setSliderMin] = useState('');
    const [sliderMax, setSliderMax] = useState('');
    const [graph, setGraph] = useState('');
    const [isLine, setIsLine] = useState(false);
    const [isNumber, setIsNumber] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [isSwitch, setIsSwitch] = useState(false);
    const [isList, setIsList] = useState(false);
    const [isSlider, setIsSlider] = useState(false);
    const [minValue, setMinValue] = useState('');
    const [maxValue, setMaxValue] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [enableMin, setEnableMin] = useState(false);
    const [enableMax, setEnableMax] = useState(false);

    const [variableOptions, setVariableOptions] = useState([]);
    const [selectedVariable, setSelectedVariable] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [listCommands, setListCommands] = useState([{ option: '', command: '' }]); // Inicializamos con un elemento por defecto

    const addListCommand = () => {
        setListCommands([...listCommands, { option: '', command: '', selected: false }]);
    };
    
    const updateListCommand = (index, field, value) => {
        const newListCommands = [...listCommands];
        newListCommands[index][field] = value;
        setListCommands(newListCommands);
    };
    
    const removeListCommand = (index) => {
        if (listCommands.length > 1) {
            const newListCommands = listCommands.filter((_, i) => i !== index);
            setListCommands(newListCommands);
        }
    };

    useEffect(() => {
        setIsLine(graph === 'line');
        setIsNumber(graph === 'number');
        setIsButton(graph === 'button');
        setIsSwitch(graph === 'switch');
        setIsList(graph === 'list');
        setIsSlider(graph === 'slider');
    }, [graph]);

    const fetchDeviceOptions = async () => {
        try {
            const data = await fetchUserDevices();
            setDeviceOptions(data.devices);
            const resp = await fetchUserData();
            setApiKey(resp.user.apiKey);
        } catch (error) {
            console.error('Error al obtener dispositivos:', error);
        }
    };

    useEffect(() => {
        fetchDeviceOptions();
    }, []);

    useEffect(() => {
        if (selectedDevice) {
            const device = deviceOptions.find(device => device._id === selectedDevice);
            setVariableOptions(device ? device.variables : []);
        } else {
            setVariableOptions([]);
        }
    }, [selectedDevice, deviceOptions]);

    const handleSave = async () => {

        // Validación de datos requeridos
        if (!title) {
            toast.error('Por favor, ingresa un título para el widget.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (!graph) {
            toast.error('Por favor, selecciona el tipo de gráfico.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (!selectedDevice) {
            toast.error('Por favor, selecciona un dispositivo.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (!selectedVariable && (isLine || isNumber)) {
            toast.error('Por favor, selecciona una variable.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (enableMin && !minValue && (isLine || isNumber)) {
            toast.error('Por favor, ingresa un valor mínimo para la alerta.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        if (enableMax && !maxValue && (isLine || isNumber) ) {
            toast.error('Por favor, ingresa un valor máximo para la alerta.', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (isButton && buttonTitle.length == 0) {
            toast.error('Por favor, ingresa un texto para el botón', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (isButton && buttonCommand.length == 0) {
            toast.error('Por favor, ingresa un comando para el botón', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (isList && !listCommands.every(command => typeof command.option === 'string' && command.option.trim().length > 0)) {
            toast.error('Por favor, ingresa un nombre válido para todas las opciones', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (isList && !listCommands.every(command => typeof command.command === 'string' && command.command.trim().length > 0)) {
            toast.error('Por favor, ingresa un comando válido para todas las opciones', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (isSlider && !sliderMax) {
            toast.error('Por favor, ingresa un valor máximo', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        if (isSlider && !sliderMin) {
            toast.error('Por favor, ingresa un valor mínimo', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }
        
        if (isSlider && Number(sliderMin) >= Number(sliderMax)) {
            toast.error('El valor mínimo debe ser menor que valor máximo', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            return;
        }

        const user = await fetchUserData();
        const widgets = await fetchUserWidgets();
        if(user.user.plan == 'free'){
            const cant = widgets.widgets.length
            if (cant >= 2) {
                // No puede crear el widget
                toast.error('No puedes crear más widgets con el plan actual', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                    });
                return
            }
        }

        if(user.user.plan == 'pro'){
            const cant = widgets.widgets.length
            if (cant >= 5) {
                // No puede crear el widget
                toast.error('No puedes crear más widgets con el plan actual', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                    });
                return
            }
        }

        const widgetConfig = {
            title,
            graphType: graph,
            minValue: enableMin ? minValue : null,
            maxValue: enableMax ? maxValue : null,
            selectedVariable,
            selectedDevice,
        };
        console.log('Widget Config:', widgetConfig);
        const resp = await createWidget({
            userId: localStorage.getItem('userId'),
            title,
            deviceId: selectedDevice,
            deviceDataId: selectedVariable,
            type: graph,
            minValue: enableMin ? minValue : null,
            maxValue: enableMax ? maxValue : null,
            buttonCommand,
            buttonTitle,
            topic: `ioteris/${apiKey}/${selectedDevice}/control`,
            sliderMax,
            sliderMin,
            sliderValue: ( (sliderMin) && (sliderMax) ) ? (Number(sliderMax)+Number(sliderMin))/2 : null,
            listCommands,

        });
        console.log(resp);
        if(resp.status === 'success'){
            toast.success('Widget creado', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
                
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else {
            toast.error(resp.error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
        }
    };

    return (
        <div className='background-popup'>
            <div className='popup-container'>
                <h2>Añadir widget</h2>
                <hr />

                {/* Campo para el título del widget */}
                <div className='widget-title'>
                    <label>Título del widget:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Ingrese el título del widget"
                    />
                </div>

                <div className='graph-type-list'>
                    <div onClick={() => setGraph('line')} className={`graph-type ${isLine ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-line.png')} alt="Gráfico lineal" />
                        <p>Gráfico lineal</p>
                    </div>
                    <div onClick={() => setGraph('number')} className={`graph-type ${isNumber ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-number.png')} alt="Valor actualizado" />
                        <p>Valor actualizado</p>
                    </div>
                    <div onClick={() => setGraph('button')} className={`graph-type ${isButton ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-button.png')} alt="Botón" />
                        <p>Botón</p>
                    </div>
                    <div onClick={() => setGraph('switch')} className={`graph-type ${isSwitch ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-switch.png')} alt="Switch" />
                        <p>Switch</p>
                    </div>
                    <div onClick={() => setGraph('list')} className={`graph-type ${isList ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-list.png')} alt="Lista/Selector" />
                        <p>Lista/Selector</p>
                    </div>
                    <div onClick={() => setGraph('slider')} className={`graph-type ${isSlider ? 'active' : ''}`}>
                        <img src={require('../../assets/graph-slider.png')} alt="Slider" />
                        <p>Slider</p>
                    </div>
                </div>

                <div className='item-variable-list'>
                    <label>Seleccionar Dispositivo:</label>
                    <select value={selectedDevice} onChange={(e) => setSelectedDevice(e.target.value)}>
                        <option value="">Seleccione un dispositivo</option>
                        {deviceOptions.map((device) => (
                            <option key={device._id} value={device._id}>{device.name}</option>
                        ))}
                    </select>

                    { (isLine || isNumber) && (
                        <>
                        <label>Seleccionar Variable:</label>
                        <select value={selectedVariable} onChange={(e) => setSelectedVariable(e.target.value)} disabled={!selectedDevice}>
                            <option value="">Seleccione una variable</option>
                            {variableOptions.map((variable) => (
                                <option key={variable.id} value={variable.id}>{variable.name}</option>
                            ))}
                        </select>
                        </>
                    )}
                    { isButton && (
                        <div className="button-options-container">
                            <input
                                type="text"
                                value={buttonTitle}
                                onChange={(e) => setButtonTitle(e.target.value)}
                                placeholder="Texto del botón"
                            />
                            <input
                                type="text"
                                value={buttonCommand}
                                onChange={(e) => setButtonCommand(e.target.value)}
                                placeholder="Comando a enviar (Puede ser un JSON o simplemente un String)"
                            />
                            <div className="topic-container">
                                <p>Tópico:</p>
                                <p className="topic">ioteris/{apiKey}/{selectedDevice}/control</p>
                            </div>
                            <p className="description-topic">Este es el tópico al cual debes suscribirte en tu equipo mediante MQTT. Para más información visita la documentación en la sección de control remoto</p>
                        </div>
                    )}
                    { isSwitch && (
                        <div className="switch-options-container">
                            <p>Este widget enviará un 1 cuando esté activado y un 0 cuando esté desactivado</p>
                            <p>Ejemplo:</p>
                            <pre>{`{"${title}": 1}`}</pre>
                            <div className="topic-container">
                                <p>Tópico:</p>
                                <p className="topic">ioteris/{apiKey}/{selectedDevice}/control</p>
                            </div>
                            <p className="description-topic">Este es el tópico al cual debes suscribirte en tu equipo mediante MQTT. Para más información visita la documentación en la sección de control remoto</p>
                        </div>
                    )}
                    { isList && (
                        <div className="list-options-container">
                            {listCommands.map((command, index) => (
                                <div className="list-option" key={index}>
                                    <input
                                        type="text"
                                        value={command.option}
                                        onChange={(e) => updateListCommand(index, 'option', e.target.value)}
                                        placeholder="Opción"
                                    />
                                    <input
                                        type="text"
                                        value={command.command}
                                        onChange={(e) => updateListCommand(index, 'command', e.target.value)}
                                        placeholder="Comando a enviar"
                                    />
                                    {listCommands.length > 1 && index > 0 && (
                                        <button onClick={() => removeListCommand(index)} className="btn-remove-option">X</button>
                                    )}
                                </div>
                            ))}
                            <button className="btn-add-option" onClick={addListCommand}>+ Agregar opción</button>
                            <div className="topic-container">
                                <p>Tópico:</p>
                                <p className="topic">ioteris/{apiKey}/{selectedDevice}/control</p>
                            </div>
                            <p className="description-topic">Este es el tópico al cual debes suscribirte en tu equipo mediante MQTT. Para más información visita la documentación en la sección de control remoto</p>
                        </div>
                    )}
                    { isSlider && (
                        <div className="slider-options-container">
                            <input
                                type="number"
                                value={sliderMin}
                                onChange={(e) => setSliderMin(e.target.value)}
                                placeholder="Valor mínimo del rango"
                            />
                            <input
                                type="number"
                                value={sliderMax}
                                onChange={(e) => setSliderMax(e.target.value)}
                                placeholder="Valor máximo del rango"
                            />
                            <p>Ejemplo:</p>
                            <pre>{`{"${title}": 200}`}</pre>
                            <div className="topic-container">
                                <p>Tópico:</p>
                                <p className="topic">ioteris/{apiKey}/{selectedDevice}/control</p>
                            </div>
                            <p className="description-topic">Este es el tópico al cual debes suscribirte en tu equipo mediante MQTT. Para más información visita la documentación en la sección de control remoto</p>
                        </div>
                    )}
                </div>

                { (isLine || isNumber) && (
                    <div className='alert-settings'>
                    <h3>Configuración de alertas</h3>
                    <div className='alert-input'>
                        <label>Habilitar valor mínimo</label>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={enableMin}
                                onChange={() => setEnableMin(!enableMin)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {enableMin && (
                        <div className='alert-input'>
                            <label>Valor mínimo:</label>
                            <input
                                type="number"
                                value={minValue}
                                onChange={(e) => setMinValue(e.target.value)}
                                placeholder="Ingrese valor mínimo"
                            />
                        </div>
                    )}

                    <div className='alert-input'>
                        <label>Habilitar valor máximo</label>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={enableMax}
                                onChange={() => setEnableMax(!enableMax)}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    {enableMax && (
                        <div className='alert-input'>
                            <label>Valor máximo:</label>
                            <input
                                type="number"
                                value={maxValue}
                                onChange={(e) => setMaxValue(e.target.value)}
                                placeholder="Ingrese valor máximo"
                            />
                        </div>
                    )}
                </div>)}

                <div className='button-group'>
                    <button className='btn-popup' id='btn-add' onClick={handleSave}>Guardar</button>
                    <button className='btn-popup' id='btn-delete' onClick={onClose}>Cancelar</button>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar
                theme="colored"
                transition={Bounce}
            />
        </div>
    );
};
