import React, { useEffect, useState } from 'react';
import { Switch, Typography } from '@mui/material';
import './GraphSwitch.scss';
import { editWidget } from '../../data/api';
import { getClient } from '../../data/mqttClient';

export const GraphSwitch = ({data}) => {
    const [isOn, setIsOn] = useState(data.switchState);
    const client = getClient(); // Reutiliza la misma conexión
  
    useEffect(() => {
      const handleMessage = (topic, message) => {
        console.log(`Mensaje recibido en ${topic}:`, message.toString());
      };

      client.on('message', handleMessage);

      return () => {
        client.off('message', handleMessage); // Desconecta el evento al desmontar el componente
      };
    }, [client]);

    const handleToggle = async (event) => {
      const newSwitchState = event.target.checked; // ✅ Valor actualizado directamente del evento
      setIsOn(newSwitchState); // Actualiza el estado local
      if (client) {
        const topic = data.topic || 'default/topic'; // Usa el topic de los props o un valor por defecto
        const message = JSON.stringify({ [data.title]: event.target.checked ? '1' : '0'}) ; // Usa el comando de los props o un mensaje por defecto
        client.publish(topic, message, { qos: 0, retain: false }, (error) => {
          if (error) {
            console.error('Error al publicar el mensaje:', error);
          } else {
            console.log(`Mensaje enviado: "${message}" en el topic: "${topic}"`);
          }
        });
      }
      const resp = await editWidget(data._id, {
        ...data,
        switchState: newSwitchState // ✅ Usa el valor actualizado
      });
    };
  

    return (
      <div className="graph-switch-container">
        <Typography variant="h5" className="switch-label">
          {isOn ? 'ON' : 'OFF'}
        </Typography>
        <Switch 
          checked={isOn} 
          onChange={handleToggle} 
          color="primary" 
          sx={{
            transform: 'scale(1.5)', // Aumenta el tamaño del switch
          }} 
        />
      </div>
    );
};
