import React, { useEffect, useState } from 'react';
import './GraphButton.scss';
import { getClient } from '../../data/mqttClient';

export const GraphButton = ({ data }) => {
  const client = getClient();

  const handleClick = () => {
    if (client) {
      const topic = data.topic || 'default/topic'; // Usa el topic de los props o un valor por defecto
      const message = data.command || 'ON'; // Usa el comando de los props o un mensaje por defecto
      client.publish(topic, message, { qos: 0, retain: false }, (error) => {
        if (error) {
          console.error('Error al publicar el mensaje:', error);
        } else {
          console.log(`Mensaje enviado: "${message}" en el topic: "${topic}"`);
        }
      });
    }
  };

    return (
        <div className="button-container">
            <button className="btn-graph-button" onClick={handleClick}>{data.buttonTitle}</button>
        </div>
    )
}
