import React, { useEffect, useState } from 'react';
import './DashboardsPage.scss';
import { Button } from '../../Components/Button/Button';
import { Widget } from '../../Components/Widget/Widget';
import { PopUpAddWidget } from '../../Components/PopUpAddWidget/PopUpAddWidget';
import { Sortable } from '@shopify/draggable';
import { fetchUserData, fetchUserWidgets, updateWidgetsOrder } from '../../data/api';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import { PopUpMaxWidgets } from '../../Components/PopUpMaxWidgets/PopUpMaxWidgets';

export const DashboardsPage = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUpWidgets, setShowPopUpWidgets] = useState(false);
  const [maxWidgets, setMaxWidgets] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [widgets, setWidgets] = useState([]); // Lista de widgets cargados
  const [loading, setLoading] = useState(true); // Estado de carga

  const fetchWidget = async () => {
    setLoading(true); // Inicia el estado de carga
    const resp = await fetchUserWidgets();
    console.log(resp);
    if (resp.status === 'success') {
      const fetchedWidgets = resp.widgets.map(widget => ({
        id: widget._id,
        title: widget.title,
        type: widget.type,
        order: widget.order, // Asegúrate de que el backend te envíe este campo
        data: generateDataForWidget(widget),
        unit: (widget.type === 'line' || widget.type === 'number') ? widget.data.data[0].unit : null,
        maxLimitActive: widget.maxLimitActive,
        minLimitActive: widget.minLimitActive,
        maxValue: widget.maxValue,
        minValue: widget.minValue,
      }));
      console.log(fetchedWidgets);
      
      // Ordenar los widgets por el campo "order"
      const sortedWidgets = fetchedWidgets.sort((a, b) => a.order - b.order);
      setWidgets(sortedWidgets); // Actualizar widgets en el estado
      const response = await fetchUserData()
      if(response.status === 'success'){
        const plan = response.user.plan
        console.log(plan);
        
        if( ( plan === 'free' && resp.widgets.length > 2) || ( plan === 'pro' && resp.widgets.length > 5)) {
          setMaxWidgets( plan === 'free' ? 2 : 5 )
          if(!showPopUpWidgets){
            setShowPopUpWidgets(true)
          }
        }
      }
    }
    setLoading(false); // Termina el estado de carga
    
  };

  useEffect(() => {
    fetchWidget(); // Cargar widgets al inicio

    // Configurar un intervalo para actualizar cada 5 segundos
    /* const intervalId = setInterval(() => {
      fetchWidget();
    }, 30000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId); */
  }, []);

  const togglePopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const toggleEditingMode = () => {
    setIsEditing(!isEditing);
  };

  const generateDataForWidget = (widget) => {
    if (widget.type === 'line') {
      const data = widget.data.data;
      return {
        labels: data.map(item => moment(item.timestamp).format('HH:mm:ss DD/MM/YYYY')),
        datasets: [
          {
            label: widget.data.variable,
            data: data.map(item => item.value),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
      };
    }
    if (widget.type === 'number') {
      const aux = widget.data.data[widget.data.data.length - 1];
      return {
        value: aux.value,
        timestamp: aux.timestamp,
        unit: aux.unit,
      };
    }
    return widget
  };

  const handleOnDragEnd = async (event) => {
      console.log('holi');
      const { oldIndex, newIndex } = event;

      if (oldIndex !== newIndex) {
          const updatedWidgets = Array.from(widgets);
          const [movedWidget] = updatedWidgets.splice(oldIndex, 1);
          updatedWidgets.splice(newIndex, 0, movedWidget);
          setWidgets(updatedWidgets);

          // Crear un array con el nuevo orden
          const newOrder = updatedWidgets.map((widget, index) => ({
              widgetId: widget.id,
              order: index
          }));

          // Llamar a la API para guardar el nuevo orden en la base de datos
          const resp = await updateWidgetsOrder(newOrder)
          if(resp.status === 'success'){
              console.log('El orden ha sido actualizado correctamente');
          } else {
            console.error('Error al intentar actualizar el orden:', resp.error);
          }
      }
  };

  useEffect(() => {
    console.log(widgets);
    
    const container = document.querySelector('.widgets');
    let sortable;
  
    if (isEditing) {
      console.log("Editing mode ON, initializing Sortable...");
      sortable = new Sortable(container, {
        draggable: '.widget',
        onEnd: handleOnDragEnd,
      });
    }
  
    const updateOrder = async () => {
      // Obtener el contenedor de los widgets
      const container = document.querySelector('.widgets');
      const children = Array.from(container.children);
      console.log(container.children)
      // Crear el nuevo orden basado en los hijos del contenedor
      const newOrder = children.map((child, index) => {
        console.log(child);
        
        const widgetId = child.getAttribute('id'); // Obtener el ID desde el atributo de datos
        return {
          widgetId: widgetId,
          order: index,
        };
      });
      console.log(newOrder);
      
      const resp = await updateWidgetsOrder(newOrder);
      if (resp.status === 'success') {
        console.log('El orden ha sido actualizado correctamente');
      } else {
        console.error('Error al intentar actualizar el orden:', resp.error);
      }
    };
  
    return () => {
      if (sortable) {
        updateOrder(); // Llama a la función async
        sortable.destroy();
        console.log("Sortable destroyed");
      }
    };
  }, [isEditing, widgets]);

  return (
    <div className='dashboards-page'>
      <Button text="+ Nuevo widget" onClick={togglePopUp} />
      <Button text={isEditing ? "Salir del modo edición" : "Modo edición"} onClick={toggleEditingMode} />
      <Button text="Refrescar" onClick={fetchWidget} /> {/* Botón de refrescar */}
      <div className={`widgets ${isEditing ? 'editing' : ''}`}>
        {loading ? (
          // Mostrar skeletons mientras los widgets se cargan
          <>
            <Skeleton variant="rectangular" width={350} height={400} sx={{margin: '10px'}}/>
            <Skeleton variant="rectangular" width={350} height={400} sx={{margin: '10px'}}/>
            <Skeleton variant="rectangular" width={350} height={400} sx={{margin: '10px'}}/>
            <Skeleton variant="rectangular" width={350} height={400} sx={{margin: '10px'}}/>
            <Skeleton variant="rectangular" width={350} height={400} sx={{margin: '10px'}}/>
            <Skeleton variant="rectangular" width={350} height={400} sx={{margin: '10px'}}/>
          </>
        ) : (
          widgets.map((widget, index) => (
            <Widget
              key={widget.id}
              className="widget"
              id={widget.id}
              title={widget.title}
              type={widget.type}
              data={widget.data}
              unit={widget.unit}
              maxLimitActive={widget.maxLimitActive}
              minLimitActive={widget.minLimitActive}
              maxValue={widget.maxValue}
              minValue={widget.minValue}
            />
          ))
        )}
      </div>
      {showPopUp && <PopUpAddWidget onClose={togglePopUp} />}
      {showPopUpWidgets && <PopUpMaxWidgets maxWidgets={maxWidgets} onClose={() => {setShowPopUpWidgets(false)}}/>}
    </div>
  );
};
