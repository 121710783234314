import React, { useState, useEffect } from 'react';
import './GraphList.scss';
import { editWidget } from '../../data/api';
import { getClient } from '../../data/mqttClient';

export const GraphList = ({ data }) => {
  const client = getClient();
  const getDefaultSelected = () => {
    const defaultCommand = data?.listCommands?.find(command => command.selected) || {};
    return defaultCommand.command || '';
  };

  const [selectedOption, setSelectedOption] = useState(getDefaultSelected);

  const handleSelectChange = async (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    if (client) {
      const topic = data.topic; // Usa el topic de los props o un valor por defecto
      const message = event.target.value; // Usa el comando de los props o un mensaje por defecto
      client.publish(topic, message, { qos: 0, retain: false }, (error) => {
        if (error) {
          console.error('Error al publicar el mensaje:', error);
        } else {
          console.log(`Mensaje enviado: "${message}" en el topic: "${topic}"`);
        }
      });
    }
    
    // Actualizar el estado de listCommands
    const newState = data.listCommands.map(command => ({
      ...command,
      selected: command.command === newValue // Solo el seleccionado se marca como true
    }));
    
    const resp = await editWidget(data._id, {
      ...data,
      listCommands: newState // ✅ Usa el valor actualizado
    });
  };

  return (
    <div className="graph-list">
      <label htmlFor="graph-selector" className="graph-list__label">Selecciona un comando</label>
      <select 
        id="graph-selector" 
        className="graph-list__select" 
        value={selectedOption} 
        onChange={handleSelectChange}
      >
        <option value="" disabled={!selectedOption}>Selecciona una opción</option>
        {data?.listCommands?.map((command, index) => (
          <option 
            key={command._id || index} 
            value={command.command} 
            selected={command.selected} 
          >
            {command.option}
          </option>
        ))}
      </select>
    </div>
  );
};
