import React, { useState } from 'react';
import './GraphSlider.scss';
import { Slider, styled } from '@mui/material';
import { editWidget } from '../../data/api';
import { getClient } from '../../data/mqttClient';

const PrettoSlider = styled(Slider)({
  margin: '0px',
  color: '#2D9CDB',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#2D9CDB',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export const GraphSlider = ({ data }) => {
  const client = getClient();
  const { sliderMax, sliderMin, sliderValue } = data;

  const handleChangeCommitted = async (event, newValue) => {
    // Se usa newValue que es el valor final después de soltar el thumb
    console.log(newValue);
    if (client) {
      const topic = data.topic; // Usa el topic de los props o un valor por defecto
      const message = JSON.stringify({ [data.title]: newValue}); // Usa el comando de los props o un mensaje por defecto
      client.publish(topic, message, { qos: 0, retain: false }, (error) => {
        if (error) {
          console.error('Error al publicar el mensaje:', error);
        } else {
          console.log(`Mensaje enviado: "${message}" en el topic: "${topic}"`);
        }
      });
    }
    const resp = await editWidget(data._id, {
      ...data,
      sliderValue: Number(newValue), // ✅ Usa el valor final después de soltar el thumb
    });
  };

  return (
    <div className="graph-slider">
      <PrettoSlider
        valueLabelDisplay="auto"
        aria-label="pretto slider"
        defaultValue={sliderValue}
        onChangeCommitted={handleChangeCommitted} // Usamos onChangeCommitted
        max={sliderMax}
        min={sliderMin}
      />
    </div>
  );
};
