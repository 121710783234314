import React, { useEffect } from 'react';
import './HomePage.scss';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const HomePage = () => {

  return (
    <div className="homepage">
      {/* Header */}
      <header className="header">
        <h1 className="logo"><span>IOT</span>ERIS</h1>
        <nav className="nav-links">
          <a href="#how-works">¿Cómo funciona?</a>
          <a href="#pricing">Precios</a>
          <a href="#faq">FAQ</a>
          <a href="/login">Inicia sesión</a>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-text">
          <h1>Recopila todos tus datos.</h1>
          <h1>Visualízalos en cualquier momento.</h1>
          <h1>Monitorea tus equipos de forma remota.</h1>
        </div>
        <img src={require('../../assets/landing-photo-1.png')} alt="Hero Illustration" />
        <img src={require('../../assets/landing-photo-2.png')} alt="Hero Illustration" />
      </section>

      {/* Features Section */}
      <section id="how-works" className="features">
        <h2>¿Cómo funciona?</h2>
        <p>
        Conecta tus dispositivos a la nube de forma segura y sencilla con IOTERIS. Monitorea y visualiza tus métricas en tiempo real desde una plataforma intuitiva y fácil de usar. Simplificamos el camino hacia el IoT para que puedas transformar tus ideas en realidad. ¡Únete y lleva tus proyectos al siguiente nivel!</p>
      </section>

      {/* Visualize Data Section */}
      <section id="visualize-data" className="steps-section">
        <h2>¿Cómo visualizar los datos en IOTERIS?</h2>
        <div className="steps-home">
          <div className="step-home">
            <h3>Paso 1</h3>
            <p>Añade un equipo.</p>
          </div>
          <div className="step-home">
            <h3>Paso 2</h3>
            <p>Envia datos desde ese equipo.</p>
          </div>
          <div className="step-home">
            <h3>Paso 3</h3>
            <p>Crea un widget para visualizarlos en tiempo real.</p>
          </div>
          <div className="step-home">
            <h3>Listo</h3>
          </div>
        </div>
      </section>

      {/* Send Commands Section */}
      <section id="send-commands" className="steps-section">
        <h2>¿Cómo enviar comandos a mis equipos?</h2>
        <div className="steps-home">
          <div className="step-home">
            <h3>Paso 1</h3>
            <p>Accede a la pestaña de "Equipos" y selecciona el dispositivo al que deseas enviar comandos.</p>
          </div>
          <div className="step-home">
            <h3>Paso 2</h3>
            <p>Elige el widget de control remoto adecuado, como un botón, switch o slider.</p>
          </div>
          <div className="step-home">
            <h3>Paso 3</h3>
            <p>Configura los parámetros del comando, como valores o acciones específicas.</p>
          </div>
          <div className="step-home">
            <h3>Paso 4</h3>
            <p>Envía el comando y verifica la respuesta del equipo en tiempo real.</p>
          </div>
          <div className="step-home">
            <h3>Listo</h3>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="pricing">
        <h2>Precios</h2>
        <div className="pricing-cards">
          <div className="card">
            <h3>Plan Free</h3>
            <p>Ideal para principiantes y pequeños proyectos personales, permitiendo a los usuarios explorar la plataforma con funciones limitadas.</p>
            <ul>
              <li>1 equipo máximo</li>
              <li>100 MB de almacenamiento de datos</li>
              <li>2 widgets máximo</li>
            </ul>
            <p>Gratis</p>
            <button onClick={ () => {
              window.location = '/register/free'
            }}>Elegir</button>
          </div>
          <div className="card">
            <h3>Plan Pro</h3>
            <p>Diseñado para usuarios individuales que necesitan más capacidades que el plan gratuito, perfecto para proyectos personales avanzados.</p>
            <ul>
              <li>3 equipos máximo</li>
              <li>500 MB de almacenamiento de datos</li>
              <li>5 widgets máximo</li>
            </ul>
            <p>$15 USD/mes</p>
            <button onClick={ () => {
              window.location = '/register/pro'
            }}>Elegir</button>
          </div>
          <div className="card">
            <h3>Plan Full</h3>
            <p>Adaptado para empresas y proyectos más grandes, ofreciendo recursos ilimitados para una gestión extensa de IoT.</p>
            <ul>
              <li>Equipos ilimitados</li>
              <li>10 GB de almacenamiento de datos</li>
              <li>Widgets ilimitados</li>
            </ul>
            <p>$30 USD/mes</p>
            <button onClick={ () => {
              window.location = '/register/full'
            }}>Elegir</button>
          </div>
        </div>
      </section>

      

      {/* FAQ Section */}
      <section id="faq" className="faq">
        <Typography sx={{fontFamily: '"Kanit", sans-serif' }} variant="h4" gutterBottom>
          Preguntas Frecuentes
        </Typography>

        <Accordion sx={{ backgroundColor: '#fff', fontFamily: '"Kanit", sans-serif' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Qué es IOTERIS?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
              IOTERIS es una plataforma que te permite conectar y monitorear dispositivos IoT en tiempo real, facilitando la visualización y gestión de datos.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Cómo añado un nuevo dispositivo a mi cuenta?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
              Para añadir un nuevo dispositivo, accede a la pestaña de "Dispositivos", y selecciona la opción "Nuevo dispositivo". Ingresa los datos requeridos y sigue las instrucciones en pantalla.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Qué tipos de widgets están disponibles en IOTERIS?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
            IOTERIS ofrece una variedad de widgets, incluyendo gráficos de serie de tiempo, indicadores de valor instantáneo y opciones de control remoto como botones, switches, listas y sliders. ¡Y esto es solo el comienzo! Seguimos agregando nuevos widgets para brindarte más herramientas de visualización y control.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>¿Cómo configuro alertas y notificaciones?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{fontFamily: '"Kanit", sans-serif' }}>
              Las alertas y notificaciones se pueden configurar en la sección de "Alertas" dentro de cada widget. Puedes establecer valores mínimos y máximos para recibir alertas automáticas.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </section>

      {/* Footer */}
      <footer className="footer">
        <h1 className="logo-footer"><span>IOT</span>ERIS</h1>
        <div className="contact">
          contacto@ioteris.com.ar
        </div>
        
        <div className="terms">
          <a href="/terms">Términos y condiciones</a>
        </div>
      </footer>
    </div>
  );
};
