import React, { useState } from 'react';
import './DocsPage.scss';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const DocsPage = () => {
  const [selectedPlatform, setSelectedPlatform] = useState('arduino_esp32');

  const platformLabels = {
    arduino_esp32: 'Arduino ESP32',
    platformio_esp32: 'PlatformIO ESP32',
    esp_idf_esp32: 'ESP-IDF ESP32',
    micropython_esp32: 'MicroPython ESP32'
  };

  const platformCodes = {
    arduino_esp32: `#include <WiFi.h>
#include <HTTPClient.h>

const char* ssid = "YOUR_SSID";
const char* password = "YOUR_PASSWORD";
const String apiKey = "YOUR_API_KEY";
const String url = "https://www.api.ioteris.com.ar/api/data";

void setup() {
  Serial.begin(115200);
  WiFi.begin(ssid, password);
  while (WiFi.status() != WL_CONNECTED) {
    delay(1000);
    Serial.println("Conectando a WiFi...");
  }
  Serial.println("Conectado a WiFi");
  sendData(25, "°C", "temperatura", "ESP32_DEVICE_ID");
}

void sendData(float value, String unit, String variable, String deviceId) {
  if (WiFi.status() == WL_CONNECTED) {
    HTTPClient http;
    http.begin(url);
    http.addHeader("Authorization", "Bearer " + apiKey);
    http.addHeader("Content-Type", "application/json");

    String payload = String("{\\"value\\":") + value + ",\\"unit\\":\\"" + unit + "\\",\\"variable\\":\\"" + variable + "\\",\\"deviceId\\":\\"" + deviceId + "\\"}";
    int httpResponseCode = http.POST(payload);
    if (httpResponseCode > 0) {
      String response = http.getString();
      Serial.println(httpResponseCode);
      Serial.println(response);
    } else {
      Serial.print("Error en la solicitud: ");
      Serial.println(httpResponseCode);
    }
    http.end();
  }
}

void loop() {}`,

    platformio_esp32: `#include <Arduino.h>
#include <WiFi.h>
#include <HTTPClient.h>

void setup() {
  Serial.begin(115200);
  WiFi.begin("YOUR_SSID", "YOUR_PASSWORD");
  while (WiFi.status() != WL_CONNECTED) {
    delay(1000);
    Serial.println("Connecting to WiFi...");
  }
  Serial.println("Connected to WiFi");
}

void loop() {
  HTTPClient http;
  http.begin("https://www.api.ioteris.com.ar/api/data");
  http.addHeader("Content-Type", "application/json");
  http.addHeader("Authorization", "Bearer " + "YOUR_API_KEY");
  int httpResponseCode = http.POST("{\"value\":25,\"unit\":\"\u00b0C\",\"variable\":\"temperature\",\"deviceId\":\"ESP32_DEVICE_ID\"}");
  http.end();
  delay(60000);
}`,

    esp_idf_esp32: `#include <stdio.h>
#include <string.h>
#include "freertos/FreeRTOS.h"
#include "freertos/task.h"
#include "esp_log.h"
#include "esp_system.h"
#include "esp_wifi.h"
#include "esp_http_client.h"
#include "nvs_flash.h"
#include "wifi_manager.h"  // Para manejar la conexión WiFi

#define WIFI_SSID "YOUR_SSID"
#define WIFI_PASS "YOUR_PASSWORD"
#define API_URL "https://www.api.ioteris.com.ar/api/data"
#define API_KEY "YOUR_API_KEY"

esp_http_client_handle_t client;

esp_err_t _http_event_handler(esp_http_client_event_t *evt) {
    switch (evt->event_id) {
        case HTTP_EVENT_ERROR:
            ESP_LOGI("HTTP_EVENT", "HTTP_EVENT_ERROR");
            break;
        case HTTP_EVENT_ON_CONNECTED:
            ESP_LOGI("HTTP_EVENT", "Connected to server");
            break;
        case HTTP_EVENT_HEADER_SENT:
            ESP_LOGI("HTTP_EVENT", "Header Sent");
            break;
        case HTTP_EVENT_ON_DATA:
            if (!esp_http_client_is_chunked_response(evt)) {
                ESP_LOGI("HTTP_EVENT", "Received data: %s", evt->data);
            }
            break;
        case HTTP_EVENT_FINISHED:
            ESP_LOGI("HTTP_EVENT", "Request finished");
            break;
        case HTTP_EVENT_DISCONNECTED:
            ESP_LOGI("HTTP_EVENT", "Disconnected from server");
            break;
    }
    return ESP_OK;
}

void wifi_init() {
    ESP_ERROR_CHECK(nvs_flash_init());
    wifi_config_t wifi_config = {
        .sta = {
            .ssid = WIFI_SSID,
            .password = WIFI_PASS
        },
    };
    ESP_ERROR_CHECK(esp_wifi_set_mode(WIFI_MODE_STA));
    ESP_ERROR_CHECK(esp_wifi_set_config(WIFI_IF_STA, &wifi_config));
    ESP_ERROR_CHECK(esp_wifi_start());
    ESP_LOGI("WiFi", "WiFi Started, Connecting...");
}

void app_main(void) {
    // Inicializa WiFi
    wifi_init();
    vTaskDelay(5000 / portTICK_PERIOD_MS);  // Espera a que el WiFi se conecte

    // Configura el cliente HTTP
    esp_http_client_config_t config = {
        .url = API_URL,
        .event_handler = _http_event_handler,
    };
    client = esp_http_client_init(&config);

    // Configura las cabeceras para la solicitud POST
    esp_http_client_set_header(client, "Content-Type", "application/json");
    esp_http_client_set_header(client, "Authorization", "Bearer " API_KEY);

    // Crea el cuerpo de la solicitud POST
    const char *data = "{\"value\":25,\"unit\":\"°C\",\"variable\":\"temperature\",\"deviceId\":\"ESP32_DEVICE_ID\"}";

    // Configura el cuerpo de la solicitud POST
    esp_http_client_set_post_field(client, data, strlen(data));

    // Realiza la solicitud POST
    esp_err_t err = esp_http_client_perform(client);

    if (err == ESP_OK) {
        ESP_LOGI("HTTP", "HTTP Status = %d", esp_http_client_get_status_code(client));
        ESP_LOGI("HTTP", "Response: %s", esp_http_client_get_response_body(client));
    } else {
        ESP_LOGE("HTTP", "HTTP GET request failed: %s", esp_err_to_name(err));
    }

    // Cierra la conexión HTTP
    esp_http_client_cleanup(client);

    // Espera 60 segundos antes de enviar otra solicitud
    vTaskDelay(60000 / portTICK_PERIOD_MS);
}
`,

    micropython_esp32: `import network
import urequests
import javascript from './../../../node_modules/react-syntax-highlighter/dist/esm/languages/hljs/javascript';

ssid = "YOUR_SSID"
password = "YOUR_PASSWORD"

station = network.WLAN(network.STA_IF)
station.active(True)
station.connect(ssid, password)

while not station.isconnected():
    pass

url = "https://www.api.ioteris.com.ar/api/data"
payload = {"value": 25, "unit": "°C", "variable": "temperatura", "deviceId": "ESP32_DEVICE_ID"}
headers = {"Authorization": "Bearer YOUR_API_KEY", "Content-Type": "application/json"}
response = urequests.post(url, json=payload, headers=headers)
print(response.text)`,
  };

  return (
    <div className="docs-page">
      <h1>Documentación para ioteris</h1>
      
      <div className="index-buttons">
        <a href="#http">Envío de datos</a>
        <a href="#mqtt">Conexión con broker MQTT</a>
      </div>

      <h2 id='http'>Conexión con Ioteris</h2>
      <p>Para enviar datos a la plataforma Ioteris, se debe realizar una solicitud HTTP POST al endpoint: <SyntaxHighlighter language="javascript" style={materialDark}>{'https://www.api.ioteris.com.ar/api/data'}</SyntaxHighlighter>La solicitud debe incluir un bearer token con la API key del usuario y un cuerpo (body) con la siguiente estructura JSON:</p>
      <pre>{`{
  "value": 25,
  "unit": "°C",
  "variable": "temperatura",
  "deviceId": "YOUR_DEVICE_ID"
}`}</pre>
      
      <p>Selecciona la plataforma para ver el código de ejemplo:</p>
      <div className="platform-selector">
        {Object.keys(platformCodes).map(platform => (
          <button 
            key={platform} 
            className={selectedPlatform === platform ? 'active' : ''} 
            onClick={() => setSelectedPlatform(platform)}
          >
            {platformLabels[platform]}
          </button>
        ))}
      </div>

      <div className="code-block">
        <SyntaxHighlighter language="cpp" style={materialDark}>
          {platformCodes[selectedPlatform]}
        </SyntaxHighlighter>
      </div>

      <h2 id='mqtt'>Conexión con el broker MQTT</h2>
      <p>Para controlar los equipos remotamente mediante MQTT, sigue los siguientes pasos:</p>
      <div className="code-block">
        <SyntaxHighlighter language="cpp" style={materialDark}>
          {`#include <WiFi.h>
#include <PubSubClient.h>

// WiFi
const char *ssid = "SSID"; // Ingresa el nombre de tu red WiFi
const char *password = "PASSWORD";  // Ingresa la contraseña de WiFi

// Broker MQTT
const char *mqtt_broker = "broker.ioteris.com.ar";
const char *topic = "ioteris/<apiKey>/<deviceId>/control";
const int mqtt_port = 1883;

WiFiClient espClient;
PubSubClient client(espClient);

void setup() {
 // Establecer la velocidad del puerto serial a 115200;
 Serial.begin(115200);
 Serial.println(topic);

 // Conectando a una red WiFi
 WiFi.begin(ssid, password);
 while (WiFi.status() != WL_CONNECTED) {
     delay(500);
     Serial.println("Conectando a WiFi..");
 }
 Serial.println("Conectado a la red WiFi");
 // Conectando al broker MQTT
 client.setServer(mqtt_broker, mqtt_port);
 client.setCallback(callback);
 while (!client.connected()) {
     String client_id = "esp32-client-";
     client_id += String(WiFi.macAddress());
     Serial.printf("El cliente %s se conecta al broker MQTT público\n", client_id.c_str());
     if (client.connect(client_id.c_str())) {
         Serial.println("Conectado al broker MQTT");
     } else {
         Serial.print("Falló con el estado ");
         Serial.print(client.state());
         delay(2000);
     }
 }
 // Publicar y suscribirse
 client.publish(topic, "Hola broker, soy ESP32 ^^");
 if (client.subscribe(topic)) {
    Serial.println("Suscripción exitosa al topic: ");
    Serial.println(topic);
  } else {
    Serial.println("Falló la suscripción al topic: ");
    Serial.println(topic);
  }
}

void callback(char *topic, byte *payload, unsigned int length) {
 Serial.print("Mensaje recibido en el topic: ");
 Serial.println(topic);
 Serial.print("Mensaje:");
 // Convertir el mensaje en una cadena de texto (string)
  String message = "";
  for (int i = 0; i < length; i++) {
    message += (char)payload[i];
  }
  
  Serial.println(message);
  Serial.println("-----------------------");

}

void loop() {
 client.loop();
}
`}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};